import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { useFirebaseContext } from '@context/firebaseContext'
import OrderSearch from './order-search'
import OrderListWrapper from './order-list'

const OrderStatusWrapper = styled.div`
  margin-top: 2rem;
  .active-orders-list h2 {
    margin: 1rem 0;
  }
`

const OrderStatus = ({ className }) => {
  const { user } = useFirebaseContext()
  return (
    <OrderStatusWrapper className={className}>
      <div className="order-status">
        <div className="cell small-12 card grid-x">{user ? <OrderListWrapper /> : <OrderSearch />}</div>
      </div>
    </OrderStatusWrapper>
  )
}

OrderStatus.displayName = 'OrderStatus'

OrderStatus.propTypes = {
  className: string,
}

export default OrderStatus
