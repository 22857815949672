// dependencies
import React from 'react'
import _isUndefined from 'lodash/isUndefined'
import { styled } from '@mui/material'
import Layout from '@components/layout'
// components
import AccountsDrawer from '@components/accounts/AccountsDrawer'

const StyledLayout = styled(Layout)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.white}!important`,
  '& .content-wrapper': {
    backgroundColor: `${theme.palette.common.white}!important`,
  },
}))

const AccountPage = () => (
  <StyledLayout>
    <AccountsDrawer />
  </StyledLayout>
)

export default AccountPage
