// dependencies
import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Grid, styled } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import { decodeTrademarkEntity } from '@helpers/string-helper'
// context
import { useStrapiContext } from '@context/strapiContext'
// components
import StrapiLink from './Link'
import '@comp-sass/shared/breadcrumbs.sass'

const Container = styled(Grid)(({ theme, spacing }) => ({
  width: 'unset',
  padding: `${theme.spacing(1)} ${spacing?.RightPadding}px ${theme.spacing(1)} ${spacing?.LeftPadding}px`,
  maxWidth: spacing?.LeftPadding > 0 || spacing?.RightPadding > 0 ? '1440px' : '104rem',
  marginLeft: spacing?.LeftPadding > 0 || spacing?.RightPadding > 0 ? 'auto' : null,
  marginRight: spacing?.LeftPadding > 0 || spacing?.RightPadding > 0 ? 'auto' : null,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
  },
}))

const BreadcrumbsGrid = styled(Grid)(({ theme }) => ({
  overflowY: 'hidden',
  overflowX: 'auto',

  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::webkitScrollbar': {
    display: 'none',
  },

  lineHeight: '20px',
  '& a': {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}))

const BreadcrumbsCenter = styled(Container)(({ theme }) => ({
  justifyContent: 'center',
  marginTop: '0.5rem',
}))

const DividerIcon = styled(NavigateNextIcon)(({ theme }) => ({
  margin: '0 0.6rem',
  color: theme.palette.text.primary,
  userSelect: 'none',
}))

const BreadcrumbsList = styled('ol')(({ theme }) => ({
  listStyle: 'none',
  display: 'flex',
}))

const BreadcrumbsListItem = styled('li')(({ theme }) => ({
  display: 'inline-block',
  fontSize: theme.typography.body2.fontSize,
  verticalAlign: 'middle',
  height: '1.2rem',
  [theme.breakpoints.up('md')]: {
    '&.overflow-hidden': {
      overflow: 'hidden',
    },
  },
}))

const BreadcrumbLink = styled(StrapiLink)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  display: 'inline-block',
  whiteSpace: 'nowrap',
}))

const LastBreadcrumbLink = styled('h1')(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  display: 'inline-block',
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  padding: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
}))

const breadcrumbsTypes = {
  LINK: 'LINK',
  DIVIDER: 'DIVIDER',
  CURRENT: 'CURRENT',
}

const topStrapiLink = ({ url, label, category }, index) => ({
  Link: {
    id: `product-bc-${index}`,
    DisplayText: label,
    InternalUrl: url,
    Title: category,
  },
})

/**
 * Creates Breadcrumbs array while adding homepage link and dividers
 * @param {object[]} crumbs
 * @returns {Object}
 */
const createBreadCrumbsList = crumbs =>
  crumbs.reduce(
    (links, { Link: LinkData }, index) => {
      if (!LinkData) return links
      const divider = { type: breadcrumbsTypes.DIVIDER, id: `${LinkData.id}-divider` }
      if (index === crumbs.length - 1) {
        return [...links, divider, { type: breadcrumbsTypes.CURRENT, ...LinkData }]
      }
      if (LinkData) {
        return [...links, divider, { type: breadcrumbsTypes.LINK, ...LinkData }]
      }
      return links
    },
    [{ InternalUrl: '/', DisplayText: 'Home', id: 'home-breadcrumbs', type: breadcrumbsTypes.LINK }],
  )

const StrapiBreadcrumbs = ({
  storeBreadcrumbs = null,
  productBreadcrumbs = null,
  productTitle = null,
  isPDP = false,
  horizontalSpacing = {
    LeftPadding: 0,
    RightPadding: 0,
  },
}) => {
  const {
    page: { BreadCrumbs, SEO },
  } = useStrapiContext()

  const links = useMemo(() => {
    if (Array.isArray(productBreadcrumbs) && productBreadcrumbs.length > 0) {
      return createBreadCrumbsList(sortBy(productBreadcrumbs, ['level']).map(topStrapiLink))
    }
    if (Array.isArray(storeBreadcrumbs) && storeBreadcrumbs.length > 0) {
      return createBreadCrumbsList(storeBreadcrumbs.map(topStrapiLink))
    }
    return createBreadCrumbsList(BreadCrumbs?.Crumbs || [])
  }, [BreadCrumbs, productBreadcrumbs, storeBreadcrumbs])

  if (!BreadCrumbs?.Crumbs && !productBreadcrumbs && !storeBreadcrumbs) {
    if (!SEO?.PageHeading && !productTitle) {
      return null
    }
    return (
      <BreadcrumbsCenter container>
        <BreadcrumbsGrid item md={12} component="nav" aria-label="breadcrumb">
          <LastBreadcrumbLink>{productTitle || SEO?.PageHeading}</LastBreadcrumbLink>
        </BreadcrumbsGrid>
      </BreadcrumbsCenter>
    )
  }

  return (
    <Container container spacing={horizontalSpacing}>
      <BreadcrumbsGrid
        item
        md={12}
        component="nav"
        className={`${isPDP ? 'breadcrumb-pdp' : ''}`}
        aria-label="breadcrumb"
      >
        <BreadcrumbsList>
          {links.map(({ type, ...data }) => {
            const view = {
              [breadcrumbsTypes.LINK]: (
                <BreadcrumbsListItem key={data.id}>
                  <BreadcrumbLink data={data} />
                </BreadcrumbsListItem>
              ),
              [breadcrumbsTypes.DIVIDER]: (
                <BreadcrumbsListItem key={data.id}>
                  <DividerIcon fontSize="small" />
                </BreadcrumbsListItem>
              ),
              [breadcrumbsTypes.CURRENT]: (
                <BreadcrumbsListItem key={data.id} className="overflow-hidden">
                  <LastBreadcrumbLink title={decodeTrademarkEntity(productTitle) || data?.DisplayText || data?.Title}>
                    {decodeTrademarkEntity(productTitle) || data?.DisplayText || data?.Title}
                  </LastBreadcrumbLink>
                </BreadcrumbsListItem>
              ),
            }
            return view[type] || null
          })}
        </BreadcrumbsList>
      </BreadcrumbsGrid>
    </Container>
  )
}

StrapiBreadcrumbs.propTypes = {
  productBreadcrumbs: PropTypes.array,
  storeBreadcrumbs: PropTypes.array,
  productTitle: PropTypes.string,
  isPDP: PropTypes.bool,
  horizontalSpacing: PropTypes.object,
}

export const StrapiPageBreadCrumbsFragment = graphql`
  fragment StrapiPageBreadCrumbsFragment on StrapiBreadCrumb {
    id
    Crumbs {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`

export default StrapiBreadcrumbs
