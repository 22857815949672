import React from 'react'
import { Skeleton, Box, Typography } from '@mui/material'

const content = new Array(2).fill(1).map((x, i) => x + i)

const ProfilePlaceholder = () => (
  <>
    {content.map(x => (
      <Box key={`placeholder-${x}`} sx={{ mb: 2 }}>
        <Typography variant="h3">
          <Skeleton />
        </Typography>
        <Skeleton variant="rectangular" width="100%">
          <div style={{ paddingTop: '150px' }} />
        </Skeleton>
        <Typography variant="body1" width="85%">
          <Skeleton />
        </Typography>
        <Typography variant="body1" width="70%">
          <Skeleton />
        </Typography>
      </Box>
    ))}
  </>
)

export default ProfilePlaceholder
