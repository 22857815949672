import { AppBar, styled, Toolbar } from '@mui/material'

export const StyledAppBar = styled(AppBar)`
  flex-grow: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`
