import React from 'react'
import { Router, Location } from '@gatsbyjs/reach-router'
import { AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'

const FramerRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <AnimatePresence exitBeforeEnter initial>
        <Router key={location.key} location={location} basepath="/account">
          {children}
        </Router>
      </AnimatePresence>
    )}
  </Location>
)

FramerRouter.propTypes = {
  children: PropTypes.node,
}
export default FramerRouter
