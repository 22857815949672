import React from 'react'

import {
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Typography,
} from '@mui/material'
import { Link } from '@gatsbyjs/reach-router'
import LogoutIcon from '@mui/icons-material/Logout'

import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { lockBodyScroll, unlockBodyScroll } from '@helpers/bodyscroll'
import { useModalsContext } from '@context/modalContext'
import { StyledAppBar, StyledToolbar } from './styled'
import links from './mobileLinks'

const onClick = (path = '/', handleClose = () => {}) => e => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === path) {
      e.preventDefault()
      handleClose()
      return
    }
    lockBodyScroll()
    handleClose()
    setTimeout(() => {
      unlockBodyScroll()
    }, 1000)
  }
}

const createOnClickHandler = (name, callback = () => {}) => () => {
  addToDataLayer_nextgen('account_link_click', {
    account_link_name: name,
    account_link_location: 'my_account',
  })
  callback()
}

function MobileAppBar() {
  const theme = useTheme()
  const { dispatch, actions } = useModalsContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [anchorEl, setAnchorEl] = React.useState(null)
  const path = typeof window !== 'undefined' && window.location.pathname

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!isMobile) return null

  return (
    <StyledAppBar component="nav" position="static">
      <StyledToolbar>
        <Typography sx={{ display: 'block' }} variant="h6" noWrap>
          My Account
        </Typography>
        <Box sx={{ display: 'flex' }}>
          {links
            .filter(item => !item.subroute)
            .map(item => {
              const IconComponent =
                item.path === path || path?.includes(item.path) || item.menu?.some(menuItem => menuItem.path === path)
                  ? item.activeIcon
                  : item.icon
              const buttonProps = item?.menu
                ? {
                    'aria-controls': 'account-mobile-menu',
                    onClick: handleMenuClick,
                    'aria-haspopup': 'true',
                  }
                : {
                    component: Link,
                    to: item.path,
                    onClick: onClick(item.path, handleClose),
                  }
              return (
                <>
                  <IconButton {...buttonProps} key={item.id} aria-label={item.label} color="inherit">
                    <IconComponent />
                  </IconButton>
                  {item?.menu && (
                    <Menu
                      sx={{
                        '& *': {
                          outline: 'none !important',
                        },
                      }}
                      id="account-mobile-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {item.menu.map(menuItem => (
                        <MenuItem
                          component={Link}
                          to={menuItem.path}
                          key={menuItem.id}
                          onClick={createOnClickHandler(menuItem.label, onClick(menuItem.path, handleClose))}
                        >
                          <ListItemIcon>
                            <menuItem.icon />
                          </ListItemIcon>
                          <ListItemText>{menuItem.label}</ListItemText>
                        </MenuItem>
                      ))}
                      <MenuItem onClick={createOnClickHandler('Log Out', onClick('', actions.logout))}>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </MenuItem>
                    </Menu>
                  )}
                </>
              )
            })}
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default MobileAppBar
