import { useEffect, useState } from 'react'

import { browserName, browserVersion } from 'react-device-detect'

import { getSessionId } from '@helpers/cloudfront'
import { getCurrentLocation } from '@helpers/geo-location'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { useFirebaseContext } from '@context/firebaseContext'
import { getCart } from '@helpers/cart'
import { getFromBrowserStorage } from '@helpers/storage'

/**
 * @typedef {Object} PageData
 * @property {string} type page type e.g. PDP, PLP, Home
 * @property {string} title page name or title
 * @property {string | null} cid Strapi page content ID
 * @property {string | null} path page route
 */

/**
 * Page load handler that sends a pageData event through dataLayer
 * @param {PageData} pageData page data object
 * @param {object[] | null} otherEvents other dataLayer events to be pushed on page load
 */
const createPageLoadHandler = setIsSent => (pageData, user = null, previous_page, otherEvents = null) => {
  const { cartItems } = getCart()
  // gets persisted original location
  const location = window.dataLayer?.find?.(o => o?.originalLocation)
  // sets analytics product
  const products =
    cartItems?.map((item, index) => {
      const cartItem = { ...item }
      if (cartItem.activeAddons) {
        cartItem.product.active_addons = cartItem.activeAddons
      }
      return analyticsProduct(cartItem.product, cartItem.quantity, index)
    }) ?? []

  // sends dataLayer event
  window.dataLayer?.push?.({
    event: 'pageData',
    page: {
      ...pageData,
      path: pageData.path || window.location.pathname,
    },
    auth_user_id: user?.uid || null,
    previous_page: window.previousPath || previous_page || (typeof window !== 'undefined' ? document?.referrer : null),
    isAuthenticated: user ? 'TRUE' : 'FALSE',
    user: {
      id: getFromBrowserStorage('local', 'ajs_user_id'),
      loggedStatus: user ? 'loggedIn' : 'loggedOut',
      sessionId: getSessionId(),
      location: getCurrentLocation(),
      browser: {
        name: browserName,
        version: browserVersion,
      },
      environment: process.env.GATSBY_ENV_SHORT,
    },
    cart: { products },
    originalLocation: location ? location.originalLocation : window.location.href,
  })
  // sends aditional events if they are present
  if (otherEvents) {
    otherEvents.forEach(event => window?.dataLayer?.push(event))
  }

  setIsSent(true)
}

/**
 * Sends pageData event to dataLayer on page load
 * @param {PageData} pageData page data object
 * @param {object[] | null} otherEvents other dataLayer events to be pushed on page load
 */
const usePageAnalytics = (pageData, otherEvents = null, previous_page) => {
  const [isSent, setIsSent] = useState(false)
  const { user, loading } = useFirebaseContext()

  useEffect(() => {
    // Check if the page and user auth have been loaded
    const onPageLoad = createPageLoadHandler(setIsSent)
    if (!isSent) {
      onPageLoad(pageData, user, previous_page, otherEvents)
    }
  }, [pageData, user, previous_page, loading, isSent, otherEvents])
}

export default usePageAnalytics
