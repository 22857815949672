import React from 'react'
import { Typography, styled } from '@mui/material'
import OrderList from '@components/order/status/order-list'
import { TitleWrapper } from '../Common'
import { PageWrapper } from '../Motion'

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  fontWeight: 500,
  color: `${theme.palette.primary.dark}`,
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
}))

const OrdersListPage = () => (
  <PageWrapper>
    <TitleWrapper>
      <StyledHeader>Orders</StyledHeader>
    </TitleWrapper>
    <OrderList />
  </PageWrapper>
)

export default OrdersListPage
