import React from 'react'
import { Typography, styled, Button, Icon, IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import OrderDetails from '@components/order/status/order-details'
import { navigate } from 'gatsby'
import { TitleWrapper } from '../Common'
import { PageWrapper } from '../Motion'

const StyledPageWrapper = styled(PageWrapper)({
  padding: '0',
})
const BackButton = styled(IconButton)(({ theme }) => ({
  textAlign: 'center',

  backgroundColor: `rgba(0, 83, 160, 0.04)`,
  height: '32px',
  width: '32px',
  marginRight: '8px',
  color: `${theme.palette.primary.main}`,
  borderRadius: '50%',
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  fontWeight: 500,
  color: theme.palette.primary.dark,
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
}))

const CaretLeft = styled(ArrowBackIosIcon)(() => ({
  fontSize: '24px',
  paddingLeft: '6px',
}))

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 600,
}))

const StyledOrderDetails = styled(OrderDetails)({
  h1: {
    display: 'none',
  },
  '& > div': {
    boxShadow: 'none',
  },
})

const OrdersDetailsPage = () => (
  <StyledPageWrapper>
    <StyledDiv>
      <BackButton onClick={() => navigate('/account/order')}>
        <CaretLeft />
      </BackButton>
      <StyledHeader>Order Details</StyledHeader>
    </StyledDiv>
    <StyledOrderDetails />
  </StyledPageWrapper>
)

export default OrdersDetailsPage
