import React from 'react'
import { TextField, Grid, Typography, IconButton, InputAdornment, Box, CircularProgress } from '@mui/material'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { PageWrapper } from '../Motion'
import { useResetPasswordForm } from './hooks'
import { Subtitle, FormGrid, TitleWrapper, InputGrid, ButtonGrid, SubmitButton } from '../Common'
import PasswordRequirements from '../Common/PasswordRequirements'
import RequiredFieldsMesage from '../Common/RequiredFieldsMesage'

const spacing = 2

const PasswordPage = () => {
  const {
    formik,
    loading,
    onVisibilityToggle,
    showPassword,
    showConfirmation,
    handleMouseDownPassword,
  } = useResetPasswordForm()
  const isDisabled = !formik.values.currentPassword || !formik.values.newPassword

  return (
    <PageWrapper>
      <TitleWrapper>
        <Typography component="h2" variant="h4">
          Password
        </Typography>
      </TitleWrapper>

      <Box>
        <RequiredFieldsMesage />
        <FormGrid component="form" container spacing={spacing} onSubmit={formik.handleSubmit}>
          <Grid container spacing={spacing} item xs={12}>
            <Grid item xs>
              <Subtitle text="Update your Rooms To Go account password" />
            </Grid>
          </Grid>
          <InputGrid item container xs={12} spacing={spacing}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                id="currentPassword"
                autoComplete="current-password"
                name="currentPassword"
                label="Current Password"
                type={showPassword ? 'text' : 'password'}
                required
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle current password visibility"
                        onClick={onVisibilityToggle('password')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                id="newPassword"
                autoComplete="new-password"
                name="newPassword"
                label="New Password"
                type={showConfirmation ? 'text' : 'password'}
                required
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle new password visibility"
                        onClick={onVisibilityToggle('passwordConfirmation')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmation ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </InputGrid>
          <InputGrid item container xs={12} spacing={spacing}>
            <Grid aria-hidden="true" item xs={12} sm={6} md={4} />
            <ButtonGrid item xs={12} sm={6} md={4}>
              {loading ? (
                <CircularProgress />
              ) : (
                <SubmitButton
                  disabled={isDisabled}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                >
                  SAVE
                </SubmitButton>
              )}
            </ButtonGrid>
          </InputGrid>
          <InputGrid item container xs={8} spacing={spacing}>
            <PasswordRequirements success={!(formik.touched.newPassword && formik.errors.newPassword)} />
          </InputGrid>
        </FormGrid>
      </Box>
    </PageWrapper>
  )
}
export default PasswordPage
