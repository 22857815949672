import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const PageWrapper = ({ children }) => (
  <motion.div
    initial={{ translateX: 120, opacity: 0 }}
    animate={{ translateX: 0, opacity: 1 }}
    exit={{ translateX: 120, opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    {children}
  </motion.div>
)

PageWrapper.propTypes = {
  children: PropTypes.node,
}

export default PageWrapper
