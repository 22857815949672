/* eslint-disable import/prefer-default-export */
import { useEffect, useState, useCallback } from 'react'
import { useUserQuery, useUpdateProfileMutation, useAuthErrorMessage } from '@hooks/auth'
import { useNotifications } from '@context/notifications'
import * as yup from 'yup'
import { useFormik } from 'formik'

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

export const validationSchema = yup.object({
  firstName: yup
    .string('Enter your first name')
    .min(2, 'First name should be of minimum 2 characters length')
    .required('First name is required'),
  lastName: yup.string('Enter your last name').required('Lastname is required'),
  phone: yup.string('Enter your phone number').matches(phoneRegExp, 'Invalid phone number'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  address1: yup.string('Enter Address Line 1').min(3, 'Provided Address is too short'),
  address2: yup.string('Enter Address Line 2').min(2, 'Provided Address is too short'),
  city: yup
    .string('Enter City')
    .min(3, 'Provided city is too short')
    .when('address1', {
      is: v => Boolean(v),
      then: schema => schema.required('City is required when Address is provided'),
    }),
  state: yup
    .string('Enter State')
    .length(2, 'State should contain 2 letters')
    .when('address1', {
      is: v => Boolean(v),
      then: schema => schema.required('State is required when Address is provided'),
    }),
  zipcode: yup
    .string('Enter your ZIP Code')
    .length(5, 'ZIP Code should contain 5 numbers')
    .matches(/^\d+$/, 'ZIP Code should have only numbers')
    .required('ZIP Code is required'),
  currentPassword: yup.string('Enter your current password'),
})

export const useProfilePage = () => {
  const { enqueue } = useNotifications()
  const [initialValues, setInitialValues] = useState(null)
  const { data, error: dataError } = useUserQuery()

  const onSuccess = useCallback(() => {
    enqueue({ variant: 'filled', body: 'Profile updated successfully.' })
  }, [enqueue])

  const { isLoading: isSubmitting, mutate, error } = useUpdateProfileMutation({ onSuccess })

  const { errorMessage, clearMessage } = useAuthErrorMessage(dataError || error)

  const onSubmit = useCallback(
    formData => {
      clearMessage()
      const updateEmail = initialValues.email !== formData.email
      const values = { ...formData, phone: formData.phone.replace(/\D+/g, '') }
      mutate({ values, oldEmail: updateEmail ? initialValues.email : null })
    },
    [initialValues, mutate, clearMessage],
  )

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      currentPassword: '',
    },
    validationSchema,
    onSubmit,
  })

  const isEmailChanged = initialValues?.email !== formik?.values?.email

  useEffect(() => {
    if (errorMessage) {
      enqueue({ variant: 'filled', body: errorMessage, severity: 'error' })
      clearMessage()
    }
  }, [errorMessage, enqueue, clearMessage])

  useEffect(() => {
    if (data && !initialValues) {
      const values = {
        firstName: data?.contact?.firstName || '',
        lastName: data?.contact?.lastName || '',
        phone: data?.contact?.phone || '',
        email: data?.contact?.email || '',
        address1: data?.address?.address1 || '',
        address2: data?.address?.address2 || '',
        city: data?.address?.city || '',
        state: data?.address?.state || '',
        zipcode: data?.address?.zip || '',
      }
      formik.setValues(values)
      setInitialValues(values)
    }
  }, [data, formik, initialValues])

  return { formik, isLoading: !initialValues, isSubmitting, errorMessage, isEmailChanged }
}
